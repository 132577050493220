var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1200", persistent: "" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "indigo" } },
                [_vm._v("mdi-file-upload")]
              ),
              _vm._v(" Import Nanotasks for '" + _vm._s(_vm.template) + "' ")
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-file-input", {
                attrs: {
                  accept: ".csv,.CSV,.json,.JSON",
                  "show-size": "",
                  label: ".csv file to upload"
                },
                on: { change: _vm.getFileContent }
              })
            ],
            1
          ),
          _vm.contents.length > 0
            ? _c(
                "v-card",
                { staticClass: "mx-6" },
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(" CSV File Parsing Results "),
                      _c("v-spacer"),
                      _c("v-text-field", {
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      dense: "",
                      headers: _vm.headers,
                      items: _vm.contents,
                      search: _vm.search
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("vue-json-pretty", { attrs: { data: _vm.contents } }),
          _vm.contents.length > 0
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: { label: "Tag Name", min: 1 },
                            model: {
                              value: _vm.tagName,
                              callback: function($$v) {
                                _vm.tagName = $$v
                              },
                              expression: "tagName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              label: "# Assignments",
                              min: 1
                            },
                            model: {
                              value: _vm.numAssignments,
                              callback: function($$v) {
                                _vm.numAssignments = $$v
                              },
                              expression: "numAssignments"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              label: "Priority",
                              min: 1
                            },
                            model: {
                              value: _vm.priority,
                              callback: function($$v) {
                                _vm.priority = $$v
                              },
                              expression: "priority"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeDialog } },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.contents.length == 0
                  },
                  on: { click: _vm.importNanotasks }
                },
                [_vm._v(" Import ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }